import { useNavigate } from "react-router-dom";
import Login from "../../components/Login";
import { PATH } from "../../routes/CustomRoutes";


export default function LoginScreen(props) {
  const navigate = useNavigate();

  const handleOnLoggedIn = () => {
    if (props.location != null && props.location.state != null) {
      navigate(props.location.state.from.pathname, {replace: true});
    }
    else {
      navigate(PATH.HOME, { replace: true });
    }
  };

  return (
    <Login onLoggedIn={handleOnLoggedIn}/>
  )
}


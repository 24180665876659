import React from 'react';
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import Cookies from 'js-cookie';
import PrivateRoute from "./PrivateRoutes";
import NotFoundScreen from '../screens/NotFoundScreen';
import LoginScreen from '../screens/web/LoginScreen';
import HomeScreen from '../screens/web/HomeScreen';
import LiffLoginScreen from '../screens/liff/LiffLoginScreen';
import LiffProfileScreen from '../screens/liff/LiffProfileScreen';
import RegisterEventScreen from '../screens/liff/RegisterEventScreen';
import LiffPaymentScreen from '../screens/liff/LiffPaymentScreen';
import VCardScreen from '../screens/VCardScreen';

export const LIFF_PATH = {  // Set Line LIFF path to /liff/<app_name>/
  PROFILE: 'profile',
  EVENT: 'event',
  PAYMENT: 'payment',
}

export const PATH = {
  LOGIN: '/login',
  HOME: '/',
  LIFF_LANDING: '/liff/:app',
  LIFF_PROFILE: `/liff-app/${LIFF_PATH.PROFILE}`,
  LIFF_EVENT: `/liff-app/${LIFF_PATH.EVENT}`,
  LIFF_PAYMENT: `/liff-app/${LIFF_PATH.PAYMENT}`,
  VCARD: '/nc/',
}

export default function CustomRoutes(props){

  const ProtectedRoute = ({
    redirectPath = PATH.LOGIN,
    children,
  }) => {
    if (Cookies.get('token') == null) {
      return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <Outlet />;
  };


  return (
    <Routes>
      <Route exact path={PATH.LOGIN} element={<LoginScreen/>} />
      <Route exact path={PATH.LIFF_LANDING} element={<LiffLoginScreen/>} />
      <Route exact path={PATH.LIFF_PROFILE} element={
        <ProtectedRoute>
          <LiffProfileScreen/>
        </ProtectedRoute>
      } />
      <Route exact path={PATH.LIFF_EVENT} element={
        <ProtectedRoute>
          <RegisterEventScreen/>
        </ProtectedRoute>
      } />
      <Route exact path={PATH.LIFF_PAYMENT} element={
        <ProtectedRoute>
          <LiffPaymentScreen/>
        </ProtectedRoute>
      } />
      <Route exact path={PATH.HOME} element={
        <ProtectedRoute>
          <HomeScreen/>
        </ProtectedRoute>
      } />
      <Route exact path={`${PATH.VCARD}:id`} element={<VCardScreen/>} />
      <Route element={<NotFoundScreen/>}/>
    </Routes>
  )
}
import React from 'react'
import PropTypes from 'prop-types';
import {
  Button, Form, Grid, Header, Message, Segment, Divider
} from 'semantic-ui-react'
import { MOBILE_WIDTH } from "../Config";


export default function Login(props) {
  const isMobile = (window.innerWidth <= MOBILE_WIDTH);

  return (
    <div className='login-form'>
      <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 100%;
        }
      `}
      </style>
      <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
        <Grid.Column
          style={{
            maxWidth: 450,
            display: 'flex',
            alignItems: "center"
          }}>
          <Header as='h1' color='red'>LINE Login</Header>
          <Segment
            style={{ width: (isMobile ? "95%" : "100%") }} >
            <Button primary onClick={() => { props.onClick() }}>กดปุ่มเพื่อเชื่อมต่อกับ LINE</Button>
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  )
}


Login.propTypes = {
  onClick: PropTypes.func,
  liff: PropTypes.object,
};

Login.defaultProps = {
  onClick: () => { },
  liff: null,
}
export const MONTH_OPTIONS = [
  {
    key: '01',
    text: 'มกราคม',
    value: '01',
  },
  {
    key: '02',
    text: 'กุมภาพันธ์',
    value: '02',
  },
  {
    key: '03',
    text: 'มีนาคม',
    value: '03',
  },
  {
    key: '04',
    text: 'เมษายน',
    value: '04',
  },
  {
    key: '05',
    text: 'พฤษภาคม',
    value: '05',
  },
  {
    key: '06',
    text: 'มิถุนายน',
    value: '06',
  },
  {
    key: '07',
    text: 'กรกฎาคม',
    value: '07',
  },
  {
    key: '08',
    text: 'สิงหาคม',
    value: '08',
  },
  {
    key: '09',
    text: 'กันยายน',
    value: '09',
  },
  {
    key: '10',
    text: 'ตุลาคม',
    value: '10',
  },
  {
    key: '11',
    text: 'พฤศจิกายน',
    value: '11',
  },
  {
    key: '12',
    text: 'ธันวาคม',
    value: '12',
  },
]
import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import { useLiff } from 'react-liff';
import { useNavigate, useParams } from "react-router-dom";
import Login from "../../components/Login";
import LiffLogin from "../../components/LiffLogin";
import { PATH } from "../../routes/CustomRoutes";
import { Dimmer , Loader, Message } from "semantic-ui-react";
// import Vconsole from 'vconsole';


export default function LiffLoginScreen(props) {
  const navigate = useNavigate();
  const { error, isLoggedIn, isReady, liff } = useLiff();
  const  { app } = useParams();
  const [nextPath, setNextPath] = useState(PATH.LIFF_PROFILE)

  useEffect(() => {
    setNextPath(`/liff-app/${app}`);  // Manual set next route from given URL
  }, [app])

  useEffect(() => {
    if (isReady && Cookies.get('token') != null) {
      // Already logged in, auto redirect
      navigate(nextPath);
    }
  }, [isReady])

  // useEffect(() => {
  //   console.log(`Is logged in............. : ${isLoggedIn}`)
  // }, [isLoggedIn])

  // useEffect(() => {
  //   console.log(`Error...... : ${error}`)
  // }, [error])

  // useEffect(() => {
  //   console.log(`Liff........... : ${liff}`)
  // }, [liff])

  const handleOnLoggedIn = async (values) => {
    navigate(nextPath);
  };

  // for testing
  // React.useEffect(() => {  // Enable debug on for testing only
  //   const vc = new Vconsole()
  //   return () => {vc.destroy()}
  // }, [])

  return (
    <>
      {!isReady && 
        <Dimmer active>
          <Loader>เชื่อมต่อกับ LINE...</Loader>
        </Dimmer>
      }
      {isReady && !isLoggedIn &&
        <div>
          <LiffLogin onClick={() => {liff.login()}}/>
        </div>
      }
      {isReady && isLoggedIn && Cookies.get('token') == null &&
        <Login onLoggedIn={handleOnLoggedIn} liff={liff}/>
      }
      {error &&
        <Message
          negative
          icon="exclamation circle"
          header="Error on Line LIFF"
          content={error}
        />
      }
    </>
  )
}


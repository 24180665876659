import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Header, Image } from 'semantic-ui-react';
import logo from '../assets/bni_logo.png'
import { MOBILE_WIDTH } from '../Config';
import useWindowDimensions from '../frameworks/hooks/useWindowDimensions';
import { PATH } from '../routes/CustomRoutes';


export default function NotFoundScreen () {
  const { width, height } = useWindowDimensions();
  const navigate = useNavigate();


  return (
    <div className='not-found'>
      <style>
        {`
          body > div,
          body > div > div,
          body > div > div > div.not-found {
            height: 100%;
          }
        `}
      </style>
      {
        width > MOBILE_WIDTH && 
        <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle' centered columns='2' stackable>
          <Grid.Column textAlign='right'>
            <Image src={logo} size='medium' floated='right'/>
          </Grid.Column>
          <Grid.Column>
            <Header color='teal' style={{fontSize: '6rem'}}>404</Header>
            <Header size='large' color='grey'>{'Not Found!'}</Header>
            <Button 
              content={'Home'}
              color='blue'
              size='big'
              icon='arrow left'
              onClick={() => navigate(PATH.HOME)}/>
          </Grid.Column>
        </Grid>
      }
      {
        width <= MOBILE_WIDTH && 
        <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle' centered>
          <Grid.Column textAlign='center'>
            <Image src={logo} size='medium' centered/>
            <Header color='teal' style={{fontSize: '6rem'}}>404</Header>
            <Header size='large' color='grey'>{'Page Not Found'}</Header>
            <Button 
              content={'Home'}
              color='blue'
              size='big'
              icon='arrow left'
              onClick={() => navigate(PATH.HOME)}/>
          </Grid.Column>
        </Grid>
      }
    </div>
  )
}
import { useEffect, useState, createRef } from 'react';
import Moment from 'react-moment';
// import promptpay from 'promptpay-qr';
// import QRCode from 'qrcode.react';
import { Toast } from '../../frameworks/Toast';
import { GET, POST, DELETE } from '../../frameworks/HttpClient';
import { URL_MEMBER, URL_PAYMENT } from '../../constances/urls';
import { Dimmer, Loader, Grid, Header, Button, Segment, Icon, Modal, Image, Confirm, List, Divider } from 'semantic-ui-react';

const STYLE_CENTER = {
  maxWidth: 450,
  display: 'flex',
  alignItems: "center"
}

export default function LiffPaymentScreen() {

  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState(null);
  const [payments, setPayments] = useState(null);
  const [modalImage, setModalImage] = useState(null);
  const [deletePayment, setDeletePayment] = useState(null);
  const fileInputRef = createRef();

  async function fetchData() {
    try {
      setIsLoading(true);
      const profileResp = await GET(URL_MEMBER.PROFILE);
      const paymentResp = await GET(URL_PAYMENT.HISTORY);
      setProfile(profileResp.data);
      setPayments(paymentResp.data);
    } catch (err) { Toast.error(`เกิดข้อผิดพลาด : ${err.errorMessages}`) }
    finally { setIsLoading(false); }
  }

  useEffect(() => {
    fetchData();
  }, [])

  const renderSumAmount = () => {
    if (profile.sum_amount > 0) {
      return <span style={{ 'color': 'red' }}>{profile.sum_amount}</span>
    }
    else {
      return <span style={{ 'color': 'green' }}>{profile.sum_amount}</span>
    }
  }

  const renderPaymentHistory = () => (
    payments.map( p => (
      <Segment>
        {p.validated_by == null ?
          <Icon name='info circle' color='yellow' size='large' onClick={() => setModalImage(p.image)}/> :
          <Icon name='check circle' color='green' size='large' onClick={() => setModalImage(p.image)}/>
        }
        <Moment format="วันที่ DD/MM/YYYY เวลา HH:mm น.">{p.created}</Moment>
        {p.validated_by == null &&
          <>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button icon color='red' size='mini' onClick={()=>setDeletePayment(p)}>
            <Icon name='delete'/>
          </Button>
          </>
        }
      </Segment>
    ))
  )

  const handleFileChange = async (event) => {
    if (event.currentTarget.files[0]) {
      const fileReader = new FileReader();
      fileReader.onload = handleUploadPayment;
      fileReader.readAsDataURL(event.currentTarget.files[0])
    }
  }

  const handleUploadPayment = async (fileLoadEvent) => {
    try {
      setIsLoading(true);
      await POST(URL_PAYMENT.CREATE, { image: fileLoadEvent.target.result });
      fetchData();
      Toast.success('Upload เอกสารเสร็จสิ้น');
    } catch (err) { Toast.error(`เกิดข้อผิดพลาด : ${err.errorMessages}`) }
    finally { setIsLoading(false); }
  }
  
  const handleDeletePayment = async () => {
    try {
      setIsLoading(true);
      await DELETE(`${URL_PAYMENT.DELETE}${deletePayment.id}/`);
      fetchData();
      Toast.success('ลบเอกสารเสร็จสิ้น');
    } catch (err) { Toast.error(`เกิดข้อผิดพลาด : ${err}`) }
    finally { setDeletePayment(null);setIsLoading(false); }
  }

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText("4481188910");
    Toast.success('Copied');
  }

  // const renderPromptPay = () => {
  //   const mobileNumber = '0254481188910';
  //   const amount = profile.sum_amount;
  //   const payload = promptpay(mobileNumber, { amount });
  //   return (<QRCode value={payload} />)
  // }

  return (
    <div className='login-form'>
      <Confirm
        open={deletePayment != null}
        header='Image Delete Confirm'
        content='ยืนยันลบรูป​ ?'
        onCancel={()=>setDeletePayment(null)}
        onConfirm={handleDeletePayment}
      />
      <Modal
        onClose={() => setModalImage(null)}
        open={modalImage != null}
      >
        <Modal.Content>
          <Image size='medium' src={modalImage} wrapped />
        </Modal.Content>
        <Modal.Actions>
          <Button color='red' onClick={() => setModalImage(null)}>
            <Icon name='remove' /> Close
          </Button>
        </Modal.Actions>
      </Modal>
      <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 100%;
        }
      `}
      </style>
      {isLoading &&
        <Dimmer active>
          <Loader>Loading...</Loader>
        </Dimmer>
      }
      {!isLoading && profile != null && payments != null &&
        <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
          <Grid.Column style={STYLE_CENTER}>
            <input
              ref={fileInputRef}
              type="file"
              hidden
              onChange={handleFileChange}
            />
            <Header as='h1'>ยอดเงินค้างชำระ {renderSumAmount()} บาท</Header>
            {profile.sum_amount > 0 &&
              <>
                {/* {renderPromptPay()} */}
                <List>
                  <List.Item content='ธนาคารกรุงศรีอยุธยา' />
                  <List.Item content='นางสาว พัชราพร อุทกเสน' />
                  <List.Item content={<a onClick={handleCopyToClipboard}>448-1-18891-0 <Icon name='copy'/></a>} />
                </List>
                <Button
                  color="blue"
                  content="upload slip"
                  labelPosition="left"
                  icon="file"
                  onClick={() => fileInputRef.current.click()}
                />
              </>
            }
            <Header></Header> {/**empty header for add padding*/}
            <div>
              {renderPaymentHistory()}
            </div>
            </Grid.Column>
        </Grid>
      }
      <Divider>Developed By <a href="https://tailor-solutions.com/">Tailor Solutions Co,Ltd.</a></Divider>
    </div>
  )
}
import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, Container, Divider, Feed, Header, Image, Segment } from 'semantic-ui-react';
import { URL_MEMBER } from '../constances/urls';
import { GET } from '../frameworks/HttpClient';
import user from '../assets/user_icon.jpg';

export default function VCardScreen () {
	const params = useParams();
	const [data, setData] = React.useState(null);

	const fetchData = async () => {
		try {
			const response = await GET(`${URL_MEMBER.PUBLIC_PROFILE}${params.id}/`);
			console.log(response.data)
			let myData = {
				...response.data,
				position: "CEO",
				website: "https://www.tailor-solutions.com/",
			}
			setData(myData);
		} catch (error) {
			console.log(error)
		}
	}

	const gotoPage = (params) => {
		window.open(params);
	}
	
	React.useEffect(() => {
		if (params && params.id) {
			fetchData();
		}
	}, [])

  return (
    <div style={{margin: "auto", backgroundColor: "#F7F7F7", height: "100vh", paddingTop: 68}}>
			<Container style={{width: 450}}>
			<Segment>
				<Image 
					circular
					size={"tiny"}
					style={{margin: "auto", marginTop: -50}}
					src={(data && data.liff_picture_url) 
						? data.liff_picture_url 
						: user}/>
				
				<Header as='h3' textAlign={"center"} style={{marginTop: 16}}>
					{data ? data.full_name : ""}
					<Header.Subheader>
						{data ? data.email : ""}
					</Header.Subheader>
				</Header>

				<Divider horizontal>
					<Header>
						<Header.Subheader>
							{data ? data.company : ""}
						</Header.Subheader>
					</Header>
				</Divider>

				<div style={{textAlign: "center"}}>
					<Button 
						style={{marginLeft: 8, marginRight: 8}} 
						circular 
						color={"blue"}
						icon={"address book"}
						onClick={() => gotoPage(`${URL_MEMBER.VCARD}${params.id}/`)}/>
					<Button
						style={{marginLeft: 8, marginRight: 8}} 
						circular 
						color={"purple"}
						icon={"mail"}
						onClick={() => gotoPage(`mailto:${data ? data.email : ""}`)}/>
					<Button 
						style={{marginLeft: 8, marginRight: 8}} 
						circular 
						color={"green"}
						icon={"phone"}
						onClick={() => gotoPage(`tel:${data ? data.tel : ""}`)}/>
				</div>
			</Segment>
			
			<Feed style={{marginTop: 32}}>
				<Feed.Event 
					icon={"user"}
					date={"ชื่อ - สกุล"}
					summary={data ? data.full_name : ""}/>
				<Feed.Event 
					icon={"building"}
					date={"บริษัท"}
					summary={data ? data.company : ""}/>
				<Feed.Event 
					icon={"briefcase"}
					date={"ตำแหน่ง"}
					summary={data ? data.position : ""}/>
				<Feed.Event 
					icon={"phone square"}
					date={"เบอร์โทรศัพท์"}
					summary={data ? data.tel : ""}/>
				<Feed.Event 
					icon={"mail"}
					date={"อีเมล"}
					summary={data ? data.email : ""}/>
				<Feed.Event 
					icon={"world"}
					date={"เว็บไซด์"}
					summary={<a onClick={() => gotoPage(data.website)}>{data ? data.website : ""}</a>}/>
			</Feed>
			</Container>
		</div>
  )
}
import React, { useEffect, useState } from 'react';
import bni_logo from '../../assets/bni_logo.png';
import { reactLocalStorage } from 'reactjs-localstorage';
import {
  Menu, Icon, Image, Sticky
} from 'semantic-ui-react'
import { LIFF_PATH } from '../../routes/CustomRoutes';
import LiffProfileScreen from '../../screens/liff/LiffProfileScreen';
import RegisterEventScreen from '../../screens/liff/RegisterEventScreen';
import LiffPaymentScreen from '../../screens/liff/LiffPaymentScreen';
import PaymentValidation from '../../screens/web/PaymentValidation';
import ParticipantListScreen from '../../screens/web/ParticipantListScreen';


export default function HomeScreen() {
  const MENUS = {
    // Re-use LIFF page
    PROFILE: LIFF_PATH.PROFILE,
    EVENT: LIFF_PATH.EVENT,
    PAYMENT: LIFF_PATH.PAYMENT,

    // Web only page
    PAYMENT_VALIDATION: 'validate-payment',
    PARTICIPANT_LIST: 'participant-list',
  }

  const [activeMenu, setActiveMenu] = useState(MENUS.PROFILE);

  const handleMenuClick = (e, { id }) => setActiveMenu(id);

  const renderContent = () => {
    if (activeMenu === MENUS.PROFILE) {
      return <LiffProfileScreen isDesktop={true}/>
    }
    else if(activeMenu === MENUS.EVENT) {
      return <RegisterEventScreen />
    }
    else if(activeMenu === MENUS.PAYMENT) {
      return <LiffPaymentScreen />
    }
    else if(activeMenu === MENUS.PAYMENT_VALIDATION) {
      return <PaymentValidation />
    }
    else if(activeMenu === MENUS.PARTICIPANT_LIST) {
      return <ParticipantListScreen />
    }
  }

  const renderTopMenu = () => {
    return (
      <Menu stackable icon='labeled' >
        <Menu.Item>
          <Image src={bni_logo} size='mini' alt='BNI Horizon' />
        </Menu.Item>
        <Menu.Item
          id={MENUS.PROFILE}
          name={MENUS.PROFILE}
          active={activeMenu === MENUS.PROFILE}
          onClick={handleMenuClick}
        >
          <Icon name='user' />
          Profile
        </Menu.Item>
        <Menu.Item
          id={MENUS.EVENT}
          name={MENUS.EVENT}
          active={activeMenu === MENUS.EVENT}
          onClick={handleMenuClick}
        >
          <Icon name='inbox' />
          ลงทะเบียน
        </Menu.Item>
        <Menu.Item
          id={MENUS.PAYMENT}
          name={MENUS.PAYMENT}
          active={activeMenu === MENUS.PAYMENT}
          onClick={handleMenuClick}
        >
          <Icon name='dollar' />
          แจ้งชำระเงิน
        </Menu.Item>
        {localStorage.getItem('roles') != null && localStorage.getItem('roles').includes("Secretary/Treasurer") &&
          <>
            <Menu.Item
              id={MENUS.PAYMENT_VALIDATION}
              name={MENUS.PAYMENT_VALIDATION}
              active={activeMenu === MENUS.PAYMENT_VALIDATION}
              onClick={handleMenuClick}
            >
              <Icon name='money bill alternate' />
              ตรวจสอบการชำระเงิน
            </Menu.Item>
            <Menu.Item
              id={MENUS.PARTICIPANT_LIST}
              name={MENUS.PARTICIPANT_LIST}
              active={activeMenu === MENUS.PARTICIPANT_LIST}
              onClick={handleMenuClick}
            >
              <Icon name='user group' />
              รายชื่อสมาชิก
            </Menu.Item>
          </>
        }

        <Menu.Menu position='right'>
          <Menu.Item>
            <Icon name='user circle' />{reactLocalStorage.get('display_name')}
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    )
  }

  return(
    <div>
      <Sticky>
        {renderTopMenu()}
      </Sticky>
      {renderContent()}
    </div>
  )
}
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { BrowserRouter as Router } from "react-router-dom";
import CustomRoutes from './routes/CustomRoutes';
import { Dimmer, Loader } from 'semantic-ui-react';

const App = () => {
  return (
    <React.Suspense fallback={
      <Dimmer active>
        <Loader>Loading</Loader>
      </Dimmer>
    }>
      <Router>
        <div className="App">
          <ToastContainer />
          <CustomRoutes />
        </div>
      </Router>
    </React.Suspense>
  );
}

export default App;
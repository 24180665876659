import { useState, useEffect } from 'react';
import moment from 'moment';
import { GET, POST } from '../../frameworks/HttpClient';
import { URL_EVENT, URL_MEMBER } from '../../constances/urls';
import { MONTH_OPTIONS } from '../../constances/months';
import { Toast } from '../../frameworks/Toast';
import { isJson } from '../../frameworks/Util';
import BNIVerifyModal from '../../components/BNIVerifyModal';
import {
  Grid, Segment, Dimmer, Loader, Button, Checkbox, Dropdown, Header, Table, Form, TextArea, Icon
} from 'semantic-ui-react'


export default function ParticipantListScreen() {
  const [year] = useState(new Date().getFullYear());
  const [selectedYear, setSelectedYear] = useState(year);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState(null);
  const [members, setMembers] = useState([]);
  const [displayMembers, setDisplayMembers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedMember, setSelectedMember] = useState([]);
  const [lineMsg, setLineMsg] = useState('');
  const [isFlex, setIsFlex] = useState(false);
  const [flexDescription, setFlexDescription] = useState('');
  const [displayBNIVerifyModal, setDisplayBNIVerifyModal] = useState(false);

  useEffect(() => {
    (async () =>{
      try {
        setLoading(true);
        let response = await GET(`${URL_MEMBER.LIST}`)
        setMembers(response.data);
        setDisplayMembers(response.data)
      } catch (err) { Toast.error(`เกิดข้อผิดพลาด : ${err.errorMessages}`) }
      finally { setLoading(false); }
    })();
  }, [])

  useEffect(() => {
    if(isJson(lineMsg)) {
      setIsFlex(true);
    }
    else {
      setFlexDescription('');
      setIsFlex(false);
    }
  }, [lineMsg])

  const handleMonthSelectionChange = async (evt, data) => {
    setSelectedMonth(data.value);
    
    if (data.value == null || data.value === '') {
      setEvents([]);
      setEvent(null);
      return;
    }

    try {
      setEvents([]);
      setEvent(null);
      setDisplayMembers(members);
      setSelectedMember([]);
      setLineMsg('');
      setFlexDescription('');
      setLoading(true);
      let response = await GET(`${URL_EVENT.LIST_EVENT}${data.value}/${selectedYear}/`)
      setEvents(response.data);
    } catch (err) { Toast.error(`เกิดข้อผิดพลาด : ${err.errorMessages}`) }
    finally { setLoading(false); }
  }

  const handleEventChange = async (evt, data) => {
    setLineMsg('');
    setFlexDescription('');

    if (data.value == null || data.value !== "") {
      const evt = events.filter(evt => evt.id === data.value)[0]
      setEvent(evt);
      const attendee_ids = evt.attendees.map( a => a.id)
      setDisplayMembers(members.filter(m => attendee_ids.includes(m.id)));
    }
    else {
      setEvent(null);
      setDisplayMembers(members);
    }
  }

  const handleSendMessage = async () => {
    try {
      setLoading(true);
      await POST(`${URL_MEMBER.MESSAGE}`, {
        members: selectedMember,
        message: lineMsg,
        altText: flexDescription,
      })
      setSelectedMember([]);
      setLineMsg('');
      setFlexDescription('');
      Toast.success('ส่งข้อความสำเร็จ');
    } catch (err) { Toast.error(`เกิดข้อผิดพลาด : ${err.errorMessages}`) }
    finally { setLoading(false); }
  }

  const handleSubmitVerifyBNI = async () => {
    // Update the bni_verified of given attendees
    try {
      setLoading(true);
      const url = `${URL_EVENT.SUBMIT_BNI_VERIFY}${event.id}/`
      await POST(url, { attendees: event.attendees.map(a => a.id)});
      Toast.success('บันทึกข้อมูลสำเร็จ');
      setEvent(null);
      setDisplayMembers(members);
      setSelectedMember([]);
      setLineMsg('');
      setFlexDescription('');
      setDisplayBNIVerifyModal(false);
    } catch (err) { Toast.error(`เกิดข้อผิดพลาด : ${err.errorMessages}`); console.log(err)}
    finally{setLoading(false)}
  }

  const renderYearList = () => {
    const yearList = []
    for(let i=year-1; i<=year+1; i++) {
      yearList.push({
        key: i,
        text: i,
        value: i,
      })
    }
    return yearList;
  }

  const renderEventOptions = () => (
    events.map( evt => ({
      key: evt.id,
      text: `${evt.name} [${new moment(evt.event_start_date_time).format('DD/MM/YYYY - HH:mm น.')}]`,
      value: evt.id,
    }))
  )

  const renderSelectionButton =() => {
    if (selectedMember.length < displayMembers.length) {
      return (<Button onClick={ () => setSelectedMember(displayMembers.map(m => m.id))}>SelectAll</Button>)
    }
    else {
      return (<Button onClick={() => setSelectedMember([])}>ClearAll</Button>)
    }
  }

  const renderIsVerifiedBNI = (member) => {
    const attendee = event.attendees.filter(a => a.id === member.id);
    if(attendee.length > 0) {
      return (
        attendee[0].bni_verified ? 
          <Icon name='check circle' color='green'/>:
          <Icon name='minus circle' color='yellow'/>
      )
    }
    else {
      return null;
    }
  }

  const renderTableRow = () => (
    displayMembers.map( member => (
      <Table.Row>
        <Table.Cell textAlign='center'>
          <Checkbox 
            key={member.id}
            checked={selectedMember.includes(member.id)}
            onChange={(evt, data) => {
              if (data.checked) {
                setSelectedMember([...selectedMember, member.id])
              }
              else {
                setSelectedMember(selectedMember.filter(sm => sm !== member.id))
              }
            }}
          />
        </Table.Cell>
        <Table.Cell>{member.full_name}</Table.Cell>
        <Table.Cell textAlign='center'>{member.sum_amount}</Table.Cell>
        <Table.Cell textAlign='center'>{
          member.unpaid_registrations.map(reg => (
            "[" + new moment(reg.registered_event.event_start_date_time).format('MM/DD/YYYY')) + "] " +
            reg.registered_event.name
            ).join(', ')
        }</Table.Cell>
        { event != null &&
          <Table.Cell textAlign='center'>{renderIsVerifiedBNI(member)}</Table.Cell>
        }
      </Table.Row>
    ))
  )

  return (
    <div className='login-form'>
      <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 100%;
        }
      `}
      </style>
      {isLoading &&
        <Dimmer active>
          <Loader>Loading...</Loader>
        </Dimmer>
      }
      <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
        <Grid.Column
          style={{
            maxWidth: 700,
            display: 'flex',
            alignItems: "center"
          }}>
            <BNIVerifyModal 
              setOpen={setDisplayBNIVerifyModal} 
              open={displayBNIVerifyModal}
              attendees={event != null ? event.attendees : []}
              onSubmitVerify={handleSubmitVerifyBNI}
            />
            <Segment>
              <Header as='h2'>รายชื่อสมาชิก</Header>
              <Grid>
                <Grid.Column width={2}>
                  <Dropdown
                    compact
                    selection
                    defaultValue={year}
                    options={renderYearList()}
                    onChange={(evt, data)=>setSelectedYear(data.value)}
                  />
                </Grid.Column>
                <Grid.Column width={5}>
                  <Dropdown
                    placeholder='เลือกเดือน'
                    selection
                    search
                    clearable
                    options={MONTH_OPTIONS}
                    onChange={handleMonthSelectionChange}
                  />
                </Grid.Column>
                { events.length > 0 &&
                  <Grid.Column width={9}>
                    <Dropdown
                      placeholder='เลือก Event'
                      selection
                      search
                      clearable
                      options={renderEventOptions()}
                      onChange={handleEventChange}
                    />
                  </Grid.Column>
                }
              </Grid>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell textAlign='center'>{renderSelectionButton()}</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>ชื่อ - สกุล</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>เงินคงค้าง</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>คอร์สคงค้าง</Table.HeaderCell>
                    { event != null &&
                      <Table.HeaderCell textAlign='center'>
                        ส่งรายชื่อ BNI <Icon name='external alternate' link onClick={() => setDisplayBNIVerifyModal(true)}/>
                      </Table.HeaderCell>
                    }
                  </Table.Row>
                  {displayMembers.length > 0 && renderTableRow()}
                </Table.Header>
              </Table>
              <Form>
                <Form.Field>
                  <TextArea
                    placeholder='กรอกข้อความที่ต้องการส่ง'
                    value={lineMsg}
                    onChange={(evt, data) => setLineMsg(data.value)} />
                </Form.Field>
                {isFlex && 
                  <Form.Field>
                    <input 
                      placeholder='Description' 
                      value={flexDescription}
                      onChange={evt => setFlexDescription(evt.target.value)}
                    />
                  </Form.Field>
                }
                <Form.Field>
                  <Button 
                    color={isFlex ? 'purple' : 'blue'}
                    onClick={handleSendMessage}
                    disabled={selectedMember.length === 0}
                  >
                    ส่งข้อความ {isFlex && <span>(Flex)</span>}
                  </Button>
                </Form.Field>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid>
    </div>
  )
}
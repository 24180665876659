import { useEffect, useState } from "react";
import { GET } from "../../frameworks/HttpClient";
import { URL_EVENT } from "../../constances/urls";
import Event from "../../components/Event";
import { List, Dimmer, Loader, Divider } from "semantic-ui-react";
import { Toast } from "../../frameworks/Toast";


export default function RegisterEventScreen() {
  const [events, setEvents] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  async function fetchData() {
    try {
      setIsLoading(true);
      setEvents(null);
      let resp = await GET(URL_EVENT.ACTIVE_EVENT)
      setEvents(resp.data);
    } catch(err) {
      Toast.error(`เกิดข้อผิดพลาด : ${err.errorMessages}`)
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const renderListBody = () => {
    if(events != null) {
      return events.map( evt => (
        <Event 
          key={evt.id}
          isValidated={evt.register != null && evt.register.payment_datetime != null}
          {...evt}
          onUpdated={fetchData}
        />
      ))
    }
    else {
      return null;
    }
  }

  return (
    <>
      <Dimmer active={isLoading}>
        <Loader>Loading</Loader>
      </Dimmer>
      <List relaxed>
        {renderListBody()}
      </List>
      <Divider>Developed By <a href="https://tailor-solutions.com/">Tailor Solutions Co,Ltd.</a></Divider>
    </>
  )
}
import { useEffect, useState } from 'react';
import { Toast } from '../frameworks/Toast';
import {
  Modal, TextArea, Button
} from 'semantic-ui-react'

export default function BNIVerifyModal(props) {
  const [displayNames, setDisplayNames] = useState([])
  const [sumPrice, setSumPrice] = useState(0);

  useEffect(() => {
    if (props.attendees.length > 0) {
      setDisplayNames(props.attendees.filter(a => a.bni_verified === false).map(a => a.full_name).join("\r\n"))
      setSumPrice(props.attendees.reduce((sum, a) => a.bni_verified === false ? sum + a.price : sum, 0))
    }
  }, [props.attendees])

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(displayNames);
    Toast.success('Copied');
  }

  return(
    <Modal
      onClose={() => props.setOpen(false)}
      onOpen={() => props.setOpen(true)}
      open={props.open}
    >
      <Modal.Header>ส่งรายชื่อสมาชิกไป BNI กลาง (รวมยอดเงิน {sumPrice} บาท)</Modal.Header>
      <Modal.Content>
        <TextArea 
          style={{ minHeight: 100, width: '100%' }}
          value={displayNames}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button color='blue' onClick={handleCopyToClipboard}>
          Copy
        </Button>
        <Button color='green' onClick={() => { props.onSubmitVerify()}}>
          ยืนยันกรอกข้อมูล
        </Button>
        <Button color='grey' onClick={() => props.setOpen(false)}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
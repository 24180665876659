import { useState, useEffect } from 'react';
import moment from 'moment';
import Moment from 'react-moment';
import { POST } from '../frameworks/HttpClient';
import { URL_EVENT } from '../constances/urls';
import { Segment, Header, Button, List, Label, Icon } from "semantic-ui-react";
import { Toast } from '../frameworks/Toast';

export default function Event(props) {
  // const ics = require('ics');
  const [isLoading, setIsLoading] = useState(false);
  // const [icsFileLink, setIcsFileLink] = useState('');

  // useEffect(() => {
  //   const startDate = new moment(props.event_start_date_time).toArray();
  //   const endDate = new moment(props.event_end_date_time).toArray();
  //   // Remove seconds
  //   startDate.pop()
  //   endDate.pop()

  //   const event = {
  //     title: props.name,
  //     description: `BNI - ${props.name}`,
  //     start: startDate,
  //     end: endDate,
  //     location: "Zoom",
  //     status: 'CONFIRMED',
  //     busyStatus: 'BUSY',
  //   }
  //   ics.createEvent(event, (error, value) => {
  //     if (error) {
  //       console.log(error)
  //       return
  //     }

  //     console.log(value)
  //     const blob = new Blob([value], { type: 'text/calendar;charset=utf-8' });
  //     if (icsFileLink !== '') window.URL.revokeObjectURL(icsFileLink);
  //     setIcsFileLink(window.URL.createObjectURL(blob));
  //     // window.navigator.msSaveOrOpenBlob(blob, 'download.ics');
  //   })
  // }, [])

  async function doRegister() {
    try {
      setIsLoading(true)
      await POST(URL_EVENT.REGISTER_EVENT, {event: props.id,})
      Toast.success('ลงทะเบียนสำเร็จ')
      props.onUpdated();
    }
    catch(err) {
      Toast.error(`เกิดข้อผิดพลาด : ${err.errorMessages}`)
    }
    finally {
      setIsLoading(false)
    }
  }

  async function doUnRegister() {
    try {
      setIsLoading(true)
      await POST(URL_EVENT.UNREGISTER_EVENT, { event: props.id, })
      Toast.success('ยกเลิกลงทะเบียนสำเร็จ')
      props.onUpdated();
    }
    catch (err) {
      Toast.error(`เกิดข้อผิดพลาด : ${err.errorMessages}`)
    }
    finally {
      setIsLoading(false)
    }
  }

  const renderPrice = () => {
    if (props.is_early_bird) {
      return (
        <>
          <span style={{ 'textDecoration': 'line-through' }}>{props.price}</span>
          <span style={{'color':'red'}}>
            &nbsp;&nbsp;{props.early_bird_price} บาท
            (จนถึง <Moment date={props.early_bird_end_date_time} format="วันที่ DD/MM/YYYY เวลา HH:mm น."/>)
          </span>
        </>
      )
    }
    else {
      return <span>{props.price} บาท</span>
    }
    
  }

  const renderEventDate = () => {
    let startDate = new moment(props.event_start_date_time);
    let endDate = new moment(props.event_end_date_time);

    if (startDate.isSame(endDate, 'day')) {
      return (
        <>
          <Moment date={startDate} format="DD/MM/YYYY [HH:mm น. - " />
          <Moment date={endDate} format="HH:mm น.]" />
        </>
      )
    }
    else {
      return (
        <>
          <Moment date={startDate} format="DD/MM/YYYY - HH:mm น. ถึง " />
          <Moment date={endDate} format="DD/MM/YYYY - HH:mm น." />
        </>
      )
    }
  }

  const renderAttendees = () => {
    return props.attendees.map( (member, i) => [
      i>0 && ", ",
      member.is_paid ? <span style={{ 'color': 'green' }}>{member.name}</span> : member.name
    ])
  }

  const renderRegisterButton = () => {
    if (props.register == null) {
      return <Button color='green' floated='right' onClick={doRegister} loading={isLoading}>ลงทะเบียน</Button>
    }
    if (props.register.payment_datetime == null) {
      return <Button color='yellow' floated='right' onClick={doUnRegister} loading={isLoading}>ยกเลิกลงทะเบียน</Button>
    }
    else {
      return (
        <Label as='a' color='blue' ribbon='right'>
          <Icon name='check circle' />
          ยืนยันชำระเงินแล้ว
        </Label>
      )
    }
  }

  return(
    <Segment clearing raised loading={isLoading}>
      <Header as='h2'>{props.name}</Header>
      <List>
        <List.Item>
          <List.Icon name='dollar sign' />
          <List.Content>{renderPrice()}</List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name='marker' />
          <List.Content>{props.location_name}</List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name='calendar' />
          <List.Content>
            {renderEventDate()}
            {/* <a
              download='event.ics'
              href={icsFileLink}
            >
              Add to calendar <Icon name='external'/>
            </a> */}
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name='users' />
          <List.Content>{renderAttendees()}</List.Content>
        </List.Item>
      </List>
      {renderRegisterButton()}
    </Segment>
  )
}

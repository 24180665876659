import { useState, useEffect } from 'react';
import { GET, POST } from '../../frameworks/HttpClient';
import { URL_PAYMENT } from '../../constances/urls';
import { Toast } from '../../frameworks/Toast';
import {
  Grid, Menu, Segment, Dimmer, Loader, Image, Button, Checkbox
} from 'semantic-ui-react'


export default function PaymentValidation() {

  const [isLoading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [sumAmount, setSumAmount] = useState(0);
  const [activeItem, setActiveItem] = useState(null);
  const [registrations, setRegistrations] = useState([]);
  const [payments, setPayments] = useState([]);
  const [selectedRegistration, setSelectedRegistration] = useState([]);

  useEffect(() => {
    if (activeItem != null) {
      fetchPaymentDetail()
    }
  }, [activeItem])

  useEffect(() => {
    fetchMemberList();
  }, [])

  async function fetchMemberList() {
    try {
      setLoading(true);
      setActiveItem(null);
      setRegistrations([])
      setPayments([]);
      setSelectedRegistration([]);
      setSumAmount(0);
      const resp = await GET(URL_PAYMENT.UNVALIDATED_MEMBERS);
      setItems(resp.data);
      if (resp.data.length > 0) {
        setActiveItem(resp.data[0].id);
        setSumAmount(resp.data[0].sum_amount);
        setRegistrations(resp.data[0].unpaid_registrations);
        setSelectedRegistration([]);
      }
    } catch (err) { Toast.error(`เกิดข้อผิดพลาด : ${err.errorMessages}`) }
    finally { setLoading(false); }
  }
  
  async function fetchPaymentDetail() {
    try {
      setLoading(true);
      setPayments([]);
      const resp = await GET(`${URL_PAYMENT.DETAILS}${activeItem}/`);
      setPayments(resp.data)
    } catch (err) { Toast.error(`เกิดข้อผิดพลาด : ${err.errorMessages}`) }
    finally { setLoading(false); }
  }

  async function submitPayment() {
    try {
      setLoading(true);
      await POST(`${URL_PAYMENT.SUBMIT}`, {
        registrations: selectedRegistration,
        payments: payments.map(p => p.id),
      });
      Toast.success('ยืนยันชำระเงินสำเร็จ')
      fetchMemberList();
    } catch (err) { Toast.error(`เกิดข้อผิดพลาด : ${err.errorMessages}`) }
    finally { setLoading(false); }
  }

  const renderPaymentItems = () => items.map( item => (
      <Menu.Item
        key={item.id}
        name={item.full_name}
        active={item.id === activeItem}
        onClick={() => {
          setActiveItem(item.id);
          setSumAmount(item.sum_amount);
          setRegistrations(item.unpaid_registrations);
          setSelectedRegistration([]);
        }}
      />
  ))

  const renderRegistration = () => (
    <Segment.Group compact>
      {
        registrations.map(reg => (
          <Segment>
            <Checkbox 
              key={reg.id} 
              label={`${reg.registered_event.name} (${reg.price}) บาท`}
              onChange={(evt, data) => {
                if(data.checked){
                  setSelectedRegistration([...selectedRegistration, reg.id])
                }
                else {
                  setSelectedRegistration(selectedRegistration.filter( sr => sr !== reg.id))
                }
              }}
            />
          </Segment>
        ))
      }
      <Segment>
        {`รวมเป็นเงิน ${sumAmount} บาท`}
      </Segment>
      <Segment>
        <Button primary onClick={submitPayment}>ยืนยันชำระเงิน</Button>
      </Segment>
    </Segment.Group>
  )

  const renderPaymentImages = () => (
    <Image.Group size='medium'>
      {payments.map( p => <Image key={p.id} src={p.image}></Image>)}
    </Image.Group>
  )

  return (
    <>
      { isLoading &&
        <Dimmer active>
          <Loader>Loading....</Loader>
        </Dimmer>
      }
      <Grid>
        <Grid.Column width={4}>
          <Menu fluid vertical tabular>
            {renderPaymentItems()}
          </Menu>
        </Grid.Column>

        <Grid.Column stretched width={12}>
          <Segment>
            {renderPaymentImages()}
            {renderRegistration()}
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  )
}
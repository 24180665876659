export const URL_AUTH = {
  API_TOKEN_AUTH: "/api/users/api-token-auth/",
  LIFF_API_TOKEN_AUTH: "/api/users/liff-api-token-auth/",
  SIGN_OUT: "/api/users/sign-out/",
}

export const URL_MEMBER = {
  LIST: '/api/member/list/',
  PROFILE: '/api/member/profile/',
  MESSAGE: '/api/member/send-push-message/',
  PUBLIC_PROFILE: '/api/member/public-profile/',
  VCARD: '/api/member/vcard/',
}

export const URL_EVENT = {
  ACTIVE_EVENT: '/api/event/available-event/',
  REGISTER_EVENT: '/api/event/register-event/',
  UNREGISTER_EVENT: '/api/event/unregister-event/',
  LIST_EVENT: '/api/event/list/',
  SUBMIT_BNI_VERIFY: '/api/event/submit-bni-verified/',
}

export const URL_PAYMENT = {
  HISTORY: '/api/payment/history/',
  CREATE: '/api/payment/create/',
  DELETE: '/api/payment/delete/',
  UNVALIDATED_MEMBERS: '/api/payment/unvalidated-member/',
  DETAILS: '/api/payment/detail-list/',
  SUBMIT: '/api/payment/submit/',
}
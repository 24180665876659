import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { LiffProvider } from 'react-liff';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'semantic-ui-css/semantic.min.css'
import 'react-toastify/dist/ReactToastify.css';
import { LIFF_PATH } from "./routes/CustomRoutes";
// import reportWebVitals from './reportWebVitals';

function getLiffId() {
  const href = window.location.href
  if (href.includes(`liff/${LIFF_PATH.PROFILE}`)) {    
    return '1655996144-OyEX8Qvw';
  }
  else if (href.includes(`liff/${LIFF_PATH.EVENT}`)) {
    return '1655996144-z78AJ6jp';
  }
  else if (href.includes(`liff/${LIFF_PATH.PAYMENT}`)) {
    return '1655996144-AkjgMpPN';
  }
  else {
    return '1655996144-OyEX8Qvw';  // Default path
  }
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LiffProvider liffId={getLiffId()}>
      <App />
    </LiffProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { reactLocalStorage } from 'reactjs-localstorage';
import {
  Button, Form, Grid, Header, Message, Segment, Divider
} from 'semantic-ui-react'
import { POST } from '../frameworks/HttpClient';
import { URL_AUTH } from "../constances/urls";
import Cookies from 'js-cookie'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { MOBILE_WIDTH } from "../Config";


export default function Login(props) {
  const isMobile = (window.innerWidth <= MOBILE_WIDTH);
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("required"),
  });

  const formik = useFormik({
    validateOnChange: false,
    validationSchema: validationSchema,
    initialValues: {
      username: '',
    },
    onSubmit: (values) => onSubmitLogin(values),
  });

  const onSubmitLogin = async (values) => {
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const data = {
        liff_access_token: props.liff != null ? props.liff.getAccessToken() : null,
        ...values
      }
      const url = props.liff != null ? URL_AUTH.LIFF_API_TOKEN_AUTH : URL_AUTH.API_TOKEN_AUTH
      const response = await POST(url, data);
      Cookies.set('token', response.data.token, { expires: 1000 });
      reactLocalStorage.set('roles', response.data.member.roles.map(r => r.name));
      reactLocalStorage.set('display_name', response.data.user.liff_display_name);
      props.onLoggedIn(response.data.user);
    } catch (error) {
      setErrorMessage(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='login-form'>
      <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 100%;
        }
      `}
      </style>
      <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
        <Grid.Column
          style={{
            maxWidth: 450,
            display: 'flex',
            alignItems: "center"
          }}>
          <Header as='h1' color='red'>BNI Horizon Login</Header>
          <Segment
            style={{ width: (isMobile ? "95%" : "100%") }} >
            <Form onSubmit={formik.handleSubmit} >
              <Message
                error
                header={"Login Failed"}
                content={errorMessage}
                visible={errorMessage != null}
              />
              <Form.Input
                fluid
                name='username'
                icon='phone'
                iconPosition='left'
                placeholder={"เบอร์โทรศัพท์/Telephone Number"}
                value={formik.values.username}
                error={formik.errors.username}
                onChange={formik.handleChange}
              />
              <Button
                color='blue'
                fluid size='large'
                type='submit'
                loading={isLoading}
              >
                {"เข้าสู่ระบบ"}
              </Button>
              <Divider hidden />
            </Form>
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  )
}


Login.propTypes = {
  onLoggedIn: PropTypes.func,
  liff: PropTypes.object,
};

Login.defaultProps = {
  onLoggedIn: () => {},
  liff: null,
}